<template>
  <div class="examPack">

    <div class="list">
      <!-- <el-tabs type="card" v-if="bqList && bqList.length > 0" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in bqList" :key="index" :label="item.typeName" :name="item.uid"></el-tab-pane>
      </el-tabs> -->
      <div class="courseName">
        <div class="courseName-box" id="courseNameBox">
          <div :id="'courseName' + index" ref="courseNameItems"
            :class="{ 'courseName-item': true, 'courseName-check': courseNameId == item.uid }"
            v-for="(item, index) in bqList" :key="index" @click="chooseCourseName(item, index)">
            {{ item.typeName }}
          </div>
        </div>
      </div>
      <div class="item" v-for="(item, index) in roomList" :key="index" @click="tabId = item.id">
        <img class="item-image" src="@/assets/img/study/q1.png" />
        <div class="item-content flex-c" :class="item.seeNum && !isPurchase ? 'shiting-title' : ''">
          <div class="item-title ellipsis">
            {{ item.paperName }}
          </div>
        </div>

        <!-- <div v-if="item.paperPrice > 0 && item.status == 2" class="keep-learn" @click.stop="goOrder(item, 12)">
          立即购买
        </div> -->
        <div v-if="item.status == 1 || !item.paperPrice || item.paperPrice == 0" class="keep-learn "
          @click="checkClick(item)">
          开始答题
        </div>
      </div>
      <div class="nothing" v-if="!roomList || roomList.length == 0">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
    </div>

  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { Know } from '@/api/know'
let know = new Know()
import { getInfo, getToken } from '@/api/cookies'
export default {
  data() {
    return {
      userInfo: null, roomList: [], dialogVisible: false, bqList: [],
      activeName: null,
      courseNameId: ''
    }
  },
  props: {
    isPurchase: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    type: {
      type: Number /* 1 直播 2录播 3音频 4 图文 5面授 */,
    },
    newproductLine: {
      type: String | Number,
    },
    newkcId: {
      type: String | Number,
    },
  },
  created() {
    this.userInfo = getInfo()
    if (!this.id) {
      return
    }
    know.getContactPcLabelList({ oneLabelId: this.newproductLine, kcId: this.newkcId }).then((res) => {
      res.data.forEach(e => {
        e.uid = e.id.toString()
      });
      if (res.data && res.data.length > 0) {
        res.data.unshift({
          uid: '',
          typeName: '全部'
        });
      }
      this.bqList = res.data
      this.getContactPcPageList()
    })

  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.getContactPcPageList()
    },
    // 课程名
    async chooseCourseName(item, index) {
      this.courseNameId = item.uid;
      this.activeName = item.uid;

      sessionStorage.setItem("questionBank-courseNameId", this.courseNameId);
      this.courseNameInfo = item;
      const courseNameBox = document.getElementById("courseNameBox");
      if (index != 0) {
        index--;
        const courseNameItem = this.$refs.courseNameItems[index];
        courseNameBox.scrollLeft = courseNameItem.offsetLeft;
      }
      this.changeCourseNameShow = false;
      await this.getContactPcPageList()

    },
    getContactPcPageList() {
      know.getContactPcPageList({ oneLabelId: this.newproductLine, twoLabelId: this.activeName && this.activeName != 0 ? this.activeName : undefined, kcId: this.newkcId }).then((res) => {
        this.roomList = res.data
      })
    },
    /* 下载 */
    checkClick(item) {
      let userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.isPurchase || item.seeNum) {
        let type = this.type
        switch (this.type) {
          case 1:
            type = 2
            break
          case 2:
            type = 3
            break
          case 3:
            type = 7
            break
          case 4:
            type = 8
            break
          case 5:
            type = 10
            break
          default:
            break
        }
        this.$router.push(
          `/quesTion/examDetails?title=${item.paperName}&paperId=${item.contactId}&examType=5&type=${type}&courseId=${this.id}&IsPurchase=${this.isPurchase}`
        )
      } else {
        this.$notify({
          title: '错误',
          message: '未购买，无法学习, 请先去购买',
          type: 'error',
          duration: 2000,
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
/* 试卷列表 */
.examPack {
  .list {
    width: 680px;
    height: 419px;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    margin: 0 auto;
    margin-top: 15px;
    padding: 20px;
    overflow-y: scroll;

    .item {
      width: 620px;
      background: #F9FAFC;
      border-radius: 6px 6px 6px 6px;
      padding: 10px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 10px;

      .item-image {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 12px;
      }

      .item-content {
        width: calc(100% - 170px);

        &.shiting-title::after {
          content: "可试看";
          width: 48px;
          height: 20px;
          color: #ff5e51;
          background-color: #ffedeb;
          border-radius: 2px;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          margin-left: 20px;
        }

        .item-title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          max-width: calc(100% - 80px);
        }
      }

      .keep-learn {
        flex-shrink: 0;
        width: 90px;
        height: 30px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #2586F5;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #2586F5;

        &:hover {
          background: #2586F5;
          color: #FFFFFF;
        }
      }
    }
  }

  /* 试kan */
  .audition {
    width: 48px;
    height: 20px;
    background: #ffedeb;
    border-radius: 2px 2px 2px 2px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    line-height: 20px;
    margin-left: 8px;
    text-align: center;
    flex-shrink: 0;
  }
}

::v-deep .el-tabs__nav-wrap::after {
  height: 0 !important;


}

::v-deep .el-tabs__active-bar {
  // width: 18px !important;
  // height: 4px !important;
  background: #2586F5 !important;
  border-radius: 4px 4px 4px 4px !important;
  margin: 0 auto;
  // padding: 0 24px;
  // box-sizing: border-box !important;
  // background-clip: content-box !important;
  // transform: translateX(50%) !important;

  // position: relative;
  // left: 50%;
}

.courseName {
  // padding: 0 24px;
  margin-bottom: 28px;
  display: flex;

  .courseName-box {
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    transition: all 0.3s;

    .courseName-item {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      font-size: 15px;
      color: #333;
      border-radius: 4px;
      padding: 0 10px;
      margin-right: 8px;
      cursor: pointer;
      background: #EDF0F7;
    }

    .courseName-check {
      background-color: #E8F3FE;
      color: #2586F5;
      border: 1px solid #2586F5;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .courseName-change {
    padding: 0 8px;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #21385a;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }
  }
}
</style>
